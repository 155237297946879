var iurio = iurio || {}; //eslint-disable-line

!function () {
	'use strict';

	var toastTimeout = null;
	var toastContinueFn = undefined;

	function showToast(message, timeout) {
		$('#ToastText').text(message);
		$('#Toast').removeClass('toast-hide').addClass('toast-show');
		if (toastTimeout !== null) {
			clearTimeout(toastTimeout);
		}
		if (timeout > 0) {
			toastTimeout = setTimeout(hideToast, timeout);
		}
	}

	function hideToast() {
		if (toastTimeout !== null) {
			clearTimeout(toastTimeout);
			toastTimeout = null;
		}
		$('#Toast').removeClass('toast-show').addClass('toast-hide');
		if (typeof toastContinueFn !== 'undefined') {
			toastContinueFn();
			toastContinueFn = undefined;
		}
	}

	iurio.toast = {
		showInfo: function (message, timeout, fn) {
			if (timeout === undefined) {
				timeout = 3000;
			}
			$('#Toast').removeClass('toast-success toast-error').addClass('toast-info');
			showToast(message, timeout);
			if (typeof fn !== 'undefined') {
				toastContinueFn = fn;
			}
		},

		showSuccess: function (message, timeout, fn) {
			if (timeout === undefined) {
				timeout = 3000;
			}
			$('#Toast').removeClass('toast-info toast-error').addClass('toast-success');
			showToast(message, timeout);
			if (typeof fn !== 'undefined') {
				toastContinueFn = fn;
			}
		},

		showError: function (message, timeout, fn) {
			if (timeout === undefined) {
				timeout = 5000;
			}
			$('#Toast').removeClass('toast-info toast-success').addClass('toast-error');
			showToast(message, timeout);
			if (typeof fn !== 'undefined') {
				toastContinueFn = fn;
			}
		},

		hide: hideToast,
	};
}();

$(function () {
	$('#Toast').click(function () {
		iurio.toast.hide();
	});
});
